import React, { Component, Fragment } from "react";

class PublicView extends Component {
  render() {
    return (
      <div>
        <Fragment>
          {/* <div
            style={{
              height: "800px",
              backgroundColor: "#f7f8f9",
              textAlign: "center",
              fontFamily: "fantasy",
              fontSize: "20px"
            }}
          />
          Supposed to be Rescue Force image scroll */}
        </Fragment>
      </div>
    );
  }
}

export { PublicView };
